import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "./Calendar.css";
import axios from "axios";
import RecordModal from "./RecordModal";

function RecordCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [data, setData] = useState("");

  function showModal() {
    setIsModalShow(true);
  }

  function clickModalClose() {
    setIsModalShow(false);
  }

  async function getRecords() {
    try {
      const res = await axios.get(
        "https://walkdog.azurewebsites.net/api/walk/record",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getRecords();
  }, []);

  const getDate = (el) =>
    new Date(el.date.substring(0, el.date.indexOf("T"))).toLocaleDateString(
      "en-US",
      { year: "numeric", month: "2-digit", day: "2-digit" }
    );

  const dates =
    data &&
    data.map((el) => ({
      ...el,
      date: getDate(el).replaceAll("/", "-"),
    }));

  const mark = dates && dates.map((data) => data.date);
  const marks = Object.keys(mark).map((item) => mark[item]);

  const callDay = (clikedDay) => {
    let dataArray = [];
    data.forEach((element) => {
      if (
        moment(clikedDay).format("YYYY-MM-DD") ===
        moment(element.date).format("YYYY-MM-DD")
      ) {
        dataArray.push(element);
      }
      setModalData(dataArray);
    });

    if (marks.find((x) => x === moment(clikedDay).format("MM-DD-YYYY"))) {
      return showModal();
    }
  };

  return (
    <>
      <div className="max-w-sm mx-auto w-[90%]">
        <Calendar
          onChange={setCurrentDate}
          value={currentDate}
          formatDay={(locale, date) => moment(date).format("DD")}
          onClickDay={callDay}
          tileClassName={({ date, view }) => {
            if (marks.find((x) => x === moment(date).format("MM-DD-YYYY"))) {
              return "dot";
            }
          }}
        />
        <div
          className={
            "absolute flex justify-center items-center bg-opacity-0 " +
            (isModalShow ? "visible" : "invisible")
          }
        >
          <RecordModal
            isModalShow={isModalShow}
            onClickClose={clickModalClose}
            data={modalData}
          />
        </div>
      </div>
    </>
  );
}

export default RecordCalendar;
