import React, { useState } from "react";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
import DaumPostcode from "react-daum-postcode";

function BeforeStartWalking({ handleAddressUpdate }) {
  const [openPostcode, setOpenPostcode] = useState(true);
  // const [latitude, setLatitude] = useState(null);
  // const [longitude, setLongitude] = useState(null);
  // const [currentLocation, setCurrentLocation] = useState(null);

  const handle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode((current) => !current);
    },
  };

  // 다음 주소 선택 완료
  const handleDaumPostcodeOnComplete = (selectedLocation) => {
    const { address } = selectedLocation;
    handleAddressUpdate(address);
    setOpenPostcode(false);
  };

  // function success(e) {
  //   setLatitude(e.coords.latitude); // 위도
  //   setLongitude(e.coords.longitude); // 경도
  // }

  // function getUserLocation() {
  //   if (!navigator.geolocation) {
  //     throw "위치 정보가 지원되지 않습니다.";
  //   }
  //   navigator.geolocation.watchPosition(success);
  // }

  // useEffect(() => {
  //   getUserLocation();
  // }, []);

  // useEffect(() => {
  //   if (currentLocation !== null) {
  //     handleAddressUpdate(currentLocation);
  //   }
  // }, [currentLocation]);

  // async function getCurrentLocation() {
  //   // 위도와 경도가 안가져와졌을 때 다시 가져오기
  //   if (longitude === null || latitude === null) {
  //     getUserLocation();
  //   }

  //   try {
  //     const res = await axios.get(
  //       "https://dapi.kakao.com/v2/local/geo/coord2address.json?x=" +
  //         longitude +
  //         "&y=" +
  //         latitude,
  //       {
  //         headers: {
  //           Authorization: "KakaoAK 07c1192d5cdf2f98ea8edb3bb225c00e", //"KakaoAK {REST_API_KEY}",
  //         },
  //       }
  //     );

  //     // if (res.data.documents === undefined) {
  //     //   setCurrentLocation("서울 용산구 청파로47길 100");
  //     // }

  //     // res.data.documents?.forEach((item) => {
  //     //   setCurrentLocation(item.address.address_name);
  //     // });

  //     // FIXME: 그냥 명신관 주소 가져오기로 구현해 둠
  //     setCurrentLocation("서울 용산구 청파로47길 100");
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  return (
    <div className="px-4 py-1">
      {/* 내 위치 검색 후 출발 */}
      <div className="w-full my-2 p-3 rounded-md border border-primary">
        <div>
          <button
            className="w-full my-2 p-3 primary rounded-lg text-white text-center shadow-btn"
            onClick={() => handleAddressUpdate("서울 용산구 청파로47길 100")}
          >
            현재 위치에서 출발
          </button>

          <button
            onClick={handle.clickButton}
            className="w-full my-2 p-3 primary rounded-lg text-white text-center shadow-btn"
          >
            출발 지점 지정
          </button>
        </div>
        {!openPostcode && (
          <DaumPostcode
            onComplete={handleDaumPostcodeOnComplete} // 값을 선택할 경우 실행되는 이벤트
            autoClose={true} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
            defaultQuery="숙명여대" // 팝업을 열때 기본적으로 입력되는 검색어
          />
        )}
      </div>
    </div>
  );
}

export default BeforeStartWalking;
