export function getLocalStorage(key) {
  const value = localStorage.getItem(key);

  if (value === null) return undefined;

  return value;
}

export function getLocalStorageObject(key) {
  const value = localStorage.getItem(key);

  if (value === null) return undefined;

  return JSON.parse(value);
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function setLocalStorageObject(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStorage(key, value) {
  return localStorage.removeItem(key);
}
