import axios from "axios";
import { useEffect } from "react";

const Tmapv2 = window.Tmapv2;
const appKey = "l7xx19f5e39f9cae4be4ac94d432af1e5545"; // App Key

const Map = ({ idx, from, to, zoom }) => {
  zoom = zoom || 14;

  useEffect(() => {
    const tmap = document.querySelector("#t-map-" + idx);
    // (-) React.StrictMode로 인한 두 번 실행 방지
    if (tmap.innerHTML !== "") {
      return;
    }

    /*
    <Params>
    from: {
      x 또는 lng,
      y 또는 lat,
    }
    to: {
      x 또는 lng,
      y 또는 lat
    }
    zoom: 15
    */

    const pointFrom = {
      lat: parseFloat(from.y || from.lat),
      lng: parseFloat(from.x || from.lng),
    };
    const pointTo = {
      lat: parseFloat(to.y || to.lat),
      lng: parseFloat(to.x || to.lng),
    };

    // (0) 데이터 가공
    const pointCenter = {
      // 출발/도착 지점의 가운데 위치
      lat: (pointFrom.lat + pointTo.lat) / 2,
      lng: (pointFrom.lng + pointTo.lng) / 2,
    };

    // (1) 지도 띄우기
    const map = new Tmapv2.Map("t-map-" + idx, {
      center: new Tmapv2.LatLng(pointCenter.lat, pointCenter.lng),
      width: "100%",
      height: "100%",
      zoom,
      zoomControl: false,
      scrollWheel: false,
    });

    // (2-1) 출발 지점 마킹
    const markerS = new Tmapv2.Marker({
      position: new Tmapv2.LatLng(pointFrom.lat, pointFrom.lng),
      icon: "https://tmapapi.sktelecom.com/upload/tmap/marker/pin_r_m_s.png",
      iconSize: new Tmapv2.Size(24, 38),
      map,
    });

    // (2-2) 도착 지점 마킹
    const markerE = new Tmapv2.Marker({
      position: new Tmapv2.LatLng(pointTo.lat, pointTo.lng),
      icon: "https://tmapapi.sktelecom.com/upload/tmap/marker/pin_r_m_e.png",
      iconSize: new Tmapv2.Size(24, 38),
      map,
    });

    // (3) 경로 탐색 API 요청
    const data = {
      appKey,
      startName: "출발지",
      startX: pointFrom.lng,
      startY: pointFrom.lat,
      endName: "도착지",
      endX: pointTo.lng,
      endY: pointTo.lat,
      reqCoordType: "WGS84GEO",
      resCoordType: "EPSG3857",
    };
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    };
    axios
      .post(`https://apis.openapi.sk.com/tmap/routes/pedestrian`, data, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data.features;

        // 거리, 시간 (필요 시 사용)
        let totalDistance = resultData[0].properties.totalDistance; // 미터
        let totalTime = resultData[0].properties.totalTime; // 초

        // Result 데이터 가공
        const drawArray = [];
        for (let i in resultData) {
          const { geometry } = resultData[i];

          if (geometry.type === "LineString") {
            for (let j in geometry.coordinates) {
              const coordinate = geometry.coordinates[j];

              const point = new Tmapv2.Point(coordinate[0], coordinate[1]);
              const convertPoint =
                new Tmapv2.Projection.convertEPSG3857ToWGS84GEO(point);
              const latlng = new Tmapv2.LatLng(
                convertPoint._lat,
                convertPoint._lng
              );

              drawArray.push(latlng);
            }
          } else {
            const data = {
              markerImage: "",
              markerType: "",
              markerSize: null,
              lat: null,
              lng: null,
            };

            // if (properties.pointType === 'SP') {
            //   data.markerImage = 'https://tmapapi.sktelecom.com/upload/tmap/marker/pin_r_m_s.png';
            //   data.markerType = 'SP';
            //   data.markerSize = new Tmapv2.Size(24, 38);
            // } else if (properties.pointType === 'EP') {
            //   data.markerImage = 'https://tmapapi.sktelecom.com/upload/tmap/marker/pin_r_m_e.png';
            //   data.markerType = 'EP';
            //   data.markerSize = new Tmapv2.Size(24, 38);
            // } else {
            //   data.markerImage = 'https://topopen.tmap.co.kr/imgs/point.png';
            //   data.markerType = 'P';
            //   data.markerSize = new Tmapv2.Size(8, 8);
            // }

            /*
            API 도큐먼트에서 pointType === 'S' 또는 'E' 로 예시를 보여주지만 실제 데이터에선 'SP', 'EP' 로 전달되고 있음.
            따라서 도큐먼트대로 하면 어차피 모든 데이터가 P로 표시되고,
            위 주석 내용과 같이 SP, EP 로 하게되면 최초에 출발지 / 목적지 마킹을 비활성화 해야 정상처럼 보임 (마킹이 이중으로 등록되는 것을 방지함)
            */

            data.markerImage = "https://topopen.tmap.co.kr/imgs/point.png";
            data.markerType = "P";
            data.markerSize = new Tmapv2.Size(8, 8);

            const coordinate = geometry.coordinates;

            const point = new Tmapv2.Point(coordinate[0], coordinate[1]);
            const convertPoint =
              new Tmapv2.Projection.convertEPSG3857ToWGS84GEO(point);

            data.lat = convertPoint._lat;
            data.lng = convertPoint._lng;

            const marker = new Tmapv2.Marker({
              position: new Tmapv2.LatLng(data.lat, data.lng),
              icon: data.markerImage,
              iconSize: data.markerSize,
              map,
            });
          }
        }

        // 길 그리기
        const polyLine = new Tmapv2.Polyline({
          path: drawArray,
          strokeColor: "#DD0000",
          strokeWeight: 6,
          map,
        });
      })
      .catch((reason) => {
        console.error(reason);
      });

    //맵에 결과물 확인 하기 위한 LatLngBounds객체 생성
    const positionBounds = new Tmapv2.LatLngBounds();

    return () => {
      // 맵 초기화
      map.destroy();
    };
  }, [from, to, zoom]);

  return <div id={"t-map-" + idx} />;
};

export default Map;
