import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "./Modal";

function DestinationList({ data }) {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  function showModal() {
    setModalOpen(true);
  }

  function clickModalClose() {
    setModalOpen(false);
  }

  function handleOnClickStart() {
    const state = {
      startX: data.startCoordinate.xCoordinate,
      startY: data.startCoordinate.yCoordinate,
      destX: data.destinationCoordinate.xCoordinate,
      destY: data.destinationCoordinate.yCoordinate,
      destinationId: data.id,
    };
    navigate("/walkingPage", { state });
  }

  return (
    <div className="m-4 text-center rounded-md shadow-candidate bg-white">
      <div className="flex w-full bg-white p-3 rounded-modal text-center z-20">
        <div className="w-full">
          <div className="flex justify-center mb-3 mt-1 text-lg font-bold text-black">
            <span className="mx-1">
              {data.walkRecords.length !== 0 && "⭐️"}
            </span>
            {data.name}
          </div>
          <div className="flex ml-3 justify-between">
            <div className="justify-center text-left">
              <p>거리: {data.distance} Km</p>
              <p>예상소요시간: {data.estimateTime}분</p>
            </div>
            <button
              className="text-base p-2 primary rounded-lg text-white text-center shadow-btn"
              onClick={showModal}
            >
              산책 시작하기
            </button>
          </div>
        </div>
      </div>
      <Modal
        isModalShow={modalOpen}
        onClickClose={clickModalClose}
        onClickStart={handleOnClickStart}
        data={data}
      />
    </div>
  );
}

export default DestinationList;
