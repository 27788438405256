import React from "react";
import "./index.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import WalkPage from "./pages/WalkPage";
import WalkingPage from "./pages/WalkingPage";
import RoutePage from "./pages/RoutePage";
import LoginPage from "./pages/LoginPage";
import JoinPage from "./pages/JoinPage";
import MyPage from "./pages/MyPage";
import ProfileEditPage from "./pages/ProfileEditPage";

<script
  type="text/javascript"
  src="//dapi.kakao.com/v2/maps/sdk.js?appkey=5049da47c259d31ea12ad1f5d1f51bcd&libraries=services"
></script>;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/walkPage" element={<WalkPage />} />
        <Route path="/walkingPage" element={<WalkingPage />} />
        <Route path="/routePage" element={<RoutePage />} />

        <Route path="/" element={<MainPage />} />

        <Route path="/loginPage" element={<LoginPage />} />
        <Route path="/joinPage" element={<JoinPage />} />

        <Route path="/myPage" element={<MyPage />} />
        <Route path="/profileEdit" element={<ProfileEditPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
