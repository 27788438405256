import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

function MyPage() {
  const navigate = useNavigate();

  const [data, setData] = useState("");

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    try {
      const res = await axios.get(
        "https://walkdog.azurewebsites.net/api/users",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(res.data);
      setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  const petSize = {
    Small: "소형견",
    Mid: "중형견",
    Large: "대형견",
  };

  const petKind = {
    Small: "소형견",
    Mid: "중형견",
    Large: "대형견",
    LongHaired: "장모종-더블코트",
    SilkyCoat: "견모종-실키코트",
    SmoothHaired: "단모종",
    WireHaired: "강모종",
    GonMoJong: "권모종",
  };

  return (
    <div
      id="container"
      className="max-w-sm mx-auto min-w-[280px] divide-y-2 divide-slate-400/[.24]"
    >
      <Header />

      <div className="p-6 m-4 bg-white rounded">
        <p className="font-bold text-lg py-4">마이페이지</p>
        <div className="p-6 bg-white rounded">
          <p className="font-bold text-center text-title text-xl mb-4">
            내 정보
          </p>

          <div className="w-30px">
            <img
              className="w-full h-[164px]"
              src={data.profileImageUrl}
              alt="profile_img"
            />
          </div>

          {/* TODO: 이모지로 표현하기 */}
          <div className="gap-4 my-2 text-m font-semibold">
            <p>닉네임: {data.nickname}</p>
            <p>반려견 이름: {data.petName}</p>
            <p>
              <br />
              반려견 구분: {petSize[data.dogSize]}{" "}
            </p>
            <p>반려견 종류: {petKind[data.dogKind]}</p>
          </div>

          <button
            onClick={() => navigate("/profileEdit")}
            className="w-full mx-auto mt-6 py-2 px-4 primary text-white rounded-btn text-center shadow-btn"
          >
            프로필 편집
          </button>
        </div>
      </div>
    </div>
  );
}

export default MyPage;
