import { useNavigate } from "react-router-dom";

import Map from "./Map";

function RouteList({ data, idx }) {
  const navigate = useNavigate();

  function handleOnClickStart() {
    const state = {
      startX: data.startCoordinate.xCoordinate,
      startY: data.startCoordinate.yCoordinate,
      destX: data.destinationCoordinate.xCoordinate,
      destY: data.destinationCoordinate.yCoordinate,
      destinationId: data.id,
    };
    navigate("/walkingPage", { state });
  }

  return (
    <div className="mb-3 text-center rounded-md shadow-candidate bg-white">
      <div className="p-3 rounded-modal text-center z-20">
        <h1 className="h-full mb-3 mt-2 text-base font-bold text-black">
          {idx + 1}. [{data.addressText}]
        </h1>
        <div className="h-[150px]">
          <Map
            idx={idx}
            from={{
              lat: data.startCoordinate.yCoordinate,
              lng: data.startCoordinate.xCoordinate,
            }}
            to={{
              lat: data.destinationCoordinate.yCoordinate,
              lng: data.destinationCoordinate.xCoordinate,
            }}
          />
        </div>
        <button
          className="w-[80%] mt-4 p-2 primary rounded-lg text-white text-center shadow-btn"
          onClick={handleOnClickStart}
        >
          산책 시작하기
        </button>
      </div>
    </div>
  );
}

export default RouteList;
