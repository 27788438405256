import React from "react";

import DogImg from "../img/doggy.jpeg";
import ProfileImg from "../img/profile.png";

import { useNavigate } from "react-router-dom";

function Header({ state }) {
  const navigate = useNavigate();
  function toMyPage() {
    navigate("/myPage");
  }
  function toMainPage() {
    navigate("/");
  }

  return (
    <div className="m-4 flex justify-between items-center">
      <div className="flex gap-2 items-center cursor-pointer">
        <img
          className="rounded-[100px] w-10"
          src={DogImg}
          alt="home_img"
          onClick={toMainPage}
        />

        <p className="text-default text-[16px] text-base font-bold text-center">
          도기도기
        </p>
      </div>
      {state === 1 ? null : (
        <img
          className="w-6 h-6 cursor-pointer"
          src={ProfileImg}
          alt="home_img"
          onClick={toMyPage}
        />
      )}
    </div>
  );
}

export default Header;
