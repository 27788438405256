import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import BeforeStartWalking from "../components/BeforeStartWalking";
import Header from "../components/Header";
import DestinationList from "../components/DestinationList";
import Loading from "../components/Loading";

function WalkPage() {
  const [savedDestination, setSavedDestination] = useState([]);
  const [startAddress, setStartAddress] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const getDestination = async () => {
      try {
        const res = await axios.post(
          "https://walkdog.azurewebsites.net/api/destination/saved",
          {
            startAddress: startAddress,
            radius: 5,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setSavedDestination(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (startAddress) {
      getDestination();
    }
  }, [startAddress]);

  // TODO: BeforeStartWalking에서 받은 값 변환
  const handleAddressUpdate = (address) => {
    setStartAddress(address);
  };

  function toRoute() {
    const state = {
      transferAddress: startAddress,
    };
    navigate("/routePage", { state });
  }

  return (
    <div className="max-w-sm mx-auto">
      <Header />
      {startAddress ? (
        <>
          {/* 목적지 추천 */}
          <div className="overflow-y-auto w-full h-[560px]">
            <div className="px-4 mt-4">
              {/* 주소선택시 fetch때까지 loading 표시 */}
              {loading ? (
                <Loading />
              ) : (
                <>
                  {savedDestination.length === 0 ? (
                    <div className="h-[200px] text-center">
                      <p className="font-bold text-large text-teal-700">
                        주변에 공원이 없어요 😥
                      </p>
                      <button
                        onClick={toRoute}
                        className="w-full my-2 p-3 primary rounded-lg text-white shadow-btn"
                      >
                        주변 경로 추천 받기!
                      </button>
                    </div>
                  ) : (
                    <>
                      <p className="font-bold text-sm text-center text-teal-700">
                        반려견의 최적 산책 시간을 고려한 추천 목적지 목록이에요
                      </p>
                      {savedDestination.map((destination) => (
                        <DestinationList
                          key={destination.id}
                          data={destination}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        /* 내 위치 설정 - 주소 검색 */
        <BeforeStartWalking handleAddressUpdate={handleAddressUpdate} />
      )}
    </div>
  );
}

export default WalkPage;
