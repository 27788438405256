import React from "react";

function RecordModal({ isModalShow, onClickClose, data }) {
  const scoreIcon = {
    1: "⭐️",
    2: "⭐️⭐️",
    3: "⭐️⭐️⭐️",
    4: "⭐️⭐️⭐️⭐️",
    5: "⭐️⭐️⭐️⭐️⭐️",
  };

  return (
    <>
      <div
        className={
          "absolute flex justify-center items-center bg-opacity-0 " +
          (isModalShow ? "visible" : "invisible")
        }
      >
        <div className="fixed bg-black bg-opacity-10 flex justify-center items-center top-0 right-0 bottom-0 left-0 z-10">
          <div className="w-[420px] max-w-[75%] bg-white px-6 pt-4 pb-8 rounded-modal text-center z-20">
            <div className="flex justify-between">
              <h1 className="h-full mb-3 mt-4 text-lg font-bold text-black">
                {data.name ? data.name : "산책 기록"}
              </h1>
              <button className="rounded-lg text-center" onClick={onClickClose}>
                X
              </button>
            </div>
            <div className="h-[200px] overflow-y-scroll">
              {data.map((item, index) => {
                return (
                  <div
                    className="border-4 border-slate-500/5 p-2 mb-3"
                    key={index}
                  >
                    <h1 className="h-full mb-3 mt-4 text-base font-bold text-black">
                      {item.name ? item.name : "주변 산책지"}
                    </h1>
                    <div className="flex">
                      <div className="m-auto">
                        <p>산책 거리 </p>
                        <p>{item.distance} Km</p>
                      </div>
                      <div className="m-auto">
                        <p>산책 점수 </p>
                        <p>{item.score ? `${scoreIcon[item.score]}` : "_"}</p>
                      </div>
                    </div>
                    <div className="text-start mt-4 px-2 border-t-2 border-slate-500/5 p-2 italic">
                      "{item.memo}"
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecordModal;
