import { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function WalkDoneModal({ onClickClose }) {
  const [formValues, setFormValues] = useState({ memo: "", score: "0" });
  const navigate = useNavigate();
  const location = useLocation();
  const { startX, startY, destX, destY, destinationId } = location.state;

  const handleOnChange = (evt) => {
    const { id, value } = evt.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    if (!formValues.memo || !formValues.score) return;

    const data = {
      startCoordinate: {
        xCoordinate: startX,
        yCoordinate: startY,
      },
      destinationCoordinate: {
        xCoordinate: destX,
        yCoordinate: destY,
        destinationId,
      },
      score: formValues.score,
      memo: formValues.memo,
    };

    try {
      const response = await axios.post(
        "https://walkdog.azurewebsites.net/api/walk/done",
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        navigate("/");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="absolute flex justify-center items-center bg-opacity-0 visible">
        <div className="fixed bg-black bg-opacity-40 flex justify-center items-center top-0 right-0 bottom-0 left-0 z-10">
          <div className="w-[420px] max-w-[75%] bg-white px-6 py-4 rounded-modal text-center z-20">
            <div className="flex justify-between">
              <h1 className="h-full mb-3 mt-4 text-lg font-bold text-black">
                산책기록 남기기
              </h1>
              <button
                className="rounded-lg text-center"
                onClick={onClickClose}
                type="button"
              >
                X
              </button>
            </div>

            <form onSubmit={handleOnSubmit}>
              <div className="my-3">
                <div className="w-full flex">
                  <label className="mr-3">산책 점수: </label>
                  <input
                    className="grow"
                    type="range"
                    id="score"
                    value={formValues.score}
                    min={1}
                    max={5}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="text-start my-2">
                  <label>산책 메모: </label>
                  <textarea
                    className="w-full mt-1 p-2 border rounded-sm border-gray"
                    id="memo"
                    rows="5"
                    cols="33"
                    value={formValues.memo}
                    placeholder="오늘의 산책을 기록해주세요!"
                    onChange={handleOnChange}
                  ></textarea>
                </div>
              </div>
              <button
                className="w-full mt-4 py-3 px-4 secondary rounded-btn text-center shadow-btn"
                type="submit"
              >
                완료!
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalkDoneModal;
