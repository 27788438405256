import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import BeforeStartWalking from "../components/BeforeStartWalking";
import Header from "../components/Header";
import RouteList from "../components/RouteList";
import Loading from "../components/Loading";

function RoutePage() {
  const [savedDestination, setSavedDestination] = useState([]);
  const [startAddress, setStartAddress] = useState("");
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  // 주변 목적지 추천에서 목적지 리스트가 없는 경우 랜덤 목적지 추천
  useEffect(() => {
    if (location.state) {
      setStartAddress(location.state.transferAddress);
    }
  }, []);

  useEffect(() => {
    const getDestination = async () => {
      try {
        const res = await axios.post(
          "https://walkdog.azurewebsites.net/api/destination/random",
          {
            startAddress: startAddress,
            boundary: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setSavedDestination(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (startAddress) {
      getDestination();
    }
  }, [startAddress]);

  // TODO: BeforeStartWalking에서 받은 값 변환
  const handleAddressUpdate = (address) => {
    setStartAddress(address);
  };

  return (
    <div className="max-w-sm mx-auto">
      <Header />
      {startAddress ? (
        <>
          <div className="overflow-y-auto w-full h-[560px]">
            <div className="px-4 mt-4">
              {/* 주소선택시 fetch때까지 loading 표시 */}
              {loading && <Loading />}
              {savedDestination.map((destination, index) => (
                <RouteList key={index} data={destination} idx={index} />
              ))}
            </div>
          </div>
        </>
      ) : (
        /* 내 위치 설정 - 주소 검색 */
        <BeforeStartWalking handleAddressUpdate={handleAddressUpdate} />
      )}
    </div>
  );
}

export default RoutePage;
