import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import axios from "axios";
import Unregister from "../components/Unregister";
import { getLocalStorageObject } from "../utils/localStorage";

function ProfileEditPage() {
  const data = getLocalStorageObject("userInfo");

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  // async function getUserInfo() {
  //   try {
  //     const res = await axios.get(
  //       "https://walkdog.azurewebsites.net/api/users",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     console.log(res.data);
  //     setData(res.data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  function userLogOut() {
    localStorage.clear();
    navigate("/loginPage");
  }

  // useEffect(() => {
  //   getUserInfo();
  // }, [data]);

  const [nowPW, setNowPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [petName, setPetName] = useState("");
  const [dogSize, setDogSize] = useState(["Small", "Mid", "Large"]);
  const [dogKind, setDogKind] = useState([
    "LongHaired",
    "SilkyCoat",
    "SmoothHaired",
    "WireHaired",
    "GonMoJong",
  ]);

  const onPWHandler = (e) => {
    setNowPW(e.currentTarget.value);
  };
  const onNewPWHandler = (e) => {
    setNewPW(nowPW);
    if (e) {
      setNewPW(e.currentTarget.value);
    }
  };
  const onPetNHandler = (event) => {
    setPetName(event.currentTarget.value);
  };
  const onDogSize = (event) => {
    setDogSize(event.currentTarget.value);
  };

  const onDogKind = (event) => {
    setDogKind(event.currentTarget.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  async function putUserInfo(e) {
    e.preventDefault(); //리프레쉬 방지

    try {
      const res = await axios.put(
        "https://walkdog.azurewebsites.net/api/users",
        {
          password: newPW,
          petName: petName,
          nickname: data.nickname,
          profileImageUrl:
            `https://walkdog.azurewebsites.net/api/images/` + profileImageUrl,
          defaultLocation: {
            xCoordinate: 0,
            yCoordinate: 0,
          },
          dogSize,
          dogKind,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(res.data);
      navigate("/myPage");
    } catch (err) {
      console.error(err);
      alert("입력하신 정보를 확인해주세요!");
    }
  }

  function handleUploadFile(e) {
    imgFile(e.target.files[0]);
    UploadImg(e);
  }

  // 이미지 미리보기
  const [previewProfileImageUrl, setPreviewProfileImageUrl] = useState("");
  const imgFile = (fileBlob) => {
    console.log(fileBlob);
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setPreviewProfileImageUrl(reader.result);
        resolve();
      };
    });
  };

  const [profileImageUrl, setProfileImageUrl] = useState("");
  async function UploadImg(e) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const res = await axios.post(
        "https://walkdog.azurewebsites.net/api/images",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      );
      console.log("이미지 업로드 성공");
      console.log(res.data.name);

      setProfileImageUrl(res.data.name);
    } catch (err) {
      console.error("이미지 업로드 실패", err);
    }
  }

  return (
    <div
      id="container"
      className="max-w-sm mx-auto min-w-[280px] divide-y-2 divide-slate-400/[.24]"
    >
      <Header state={1} />

      <div className="px-4 my-4">
        <div className="px-6 py-2 bg-white rounded">
          <p className="font-bold text-center text-title text-xl my-4">
            프로필 편집
          </p>
          <form className="w-full" onSubmit={handleSubmit}>
            <p className="font-semibold text-sm py-1">아이디/비밀번호</p>
            <div className="my-2 p-3 rounded-md border border-primary">
              <div className="mb-2">
                <label>새 비밀번호: </label>
                <input
                  className="w-full p-1 border rounded-sm border-gray"
                  type="password"
                  onChange={onNewPWHandler}
                  placeholder="6자리 이상 비밀번호"
                />
              </div>
            </div>
            <p className="font-semibold text-sm py-1">내 정보</p>
            <div className="grid grid-cols-2 gap-x-3 p-3 rounded-md border border-primary">
              <div>
                {/* 이미지 파일 업로드 */}
                <label
                  className="input-file-button p-1 mx-auto text-xs rounded-sm border border-primary secondary"
                  htmlFor="input-file"
                >
                  이미지 업로드
                </label>
                <input
                  className="rounded-sm"
                  type="file"
                  accept="*/*"
                  onChange={(e) => {
                    handleUploadFile(e);
                  }}
                  id="input-file"
                  style={{ display: "none" }}
                />
                <div className="preview mt-2">
                  {previewProfileImageUrl && (
                    <img src={previewProfileImageUrl} alt="preview-img" />
                  )}
                </div>
              </div>
              <div>
                <div>
                  <label>반려견 이름: </label>
                  <input
                    className="w-full p-1 border rounded-sm border-gray"
                    type="text"
                    name="petname"
                    value={petName}
                    onChange={onPetNHandler}
                    placeholder={data.petName}
                  ></input>
                </div>
              </div>
            </div>

            <p className="font-semibold text-sm py-1">반려견 정보</p>
            <div className="my-2 p-3 rounded-md border border-primary">
              <div>
                <div
                  title="7kg이하는 소형견,
                      7kg초과~15kg이하 중형견,
                      15kg초과는 대형견으로 구분"
                  placement="top"
                >
                  <div className="font-semibold text-zinc-500">
                    반려견 구분 선택
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <label>
                    <input
                      type="radio"
                      name="DogSize"
                      value={dogSize[0]}
                      onChange={onDogSize}
                    ></input>
                    소형견
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="DogSize"
                      value={dogSize[1]}
                      onChange={onDogSize}
                    ></input>
                    중형견
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="DogSize"
                      value={dogSize[2]}
                      onChange={onDogSize}
                    ></input>
                    대형견
                  </label>
                </div>
              </div>
              <br />

              <div
                title="
                    •장모종-더블코트: 골든 리트리버, 콜리, 포메라니안
                    •견모종-실키코트: 요크셔테리어, 몰티즈, 코카스파니엘
                    •단모종: 달마시안, 비글, 불 테리어, 래브라도 리트리버, 시바견
                    •강모종: 미니어처 슈나우저, 스코티시 테리어 등 테리어종
                    •권모종: 푸들, 비숑 프리제 등"
              >
                <div className="font-semibold text-zinc-500">
                  반려견 모종 선택
                </div>
              </div>
              <div className="grid grid-cols-1">
                <label>
                  <input
                    type="radio"
                    name="DogKind"
                    value={dogKind[0]}
                    onChange={onDogKind}
                  ></input>
                  장모종-더블코트
                </label>
                <label>
                  <input
                    type="radio"
                    name="DogKind"
                    value={dogKind[1]}
                    onChange={onDogKind}
                  ></input>
                  견모종-실키코트
                </label>
                <label>
                  <input
                    type="radio"
                    name="DogKind"
                    value={dogKind[2]}
                    onChange={onDogKind}
                  ></input>
                  단모종
                </label>
                <label>
                  <input
                    type="radio"
                    name="DogKind"
                    value={dogKind[3]}
                    onChange={onDogKind}
                  ></input>
                  강모종
                </label>
                <label>
                  <input
                    type="radio"
                    name="DogKind"
                    value={dogKind[4]}
                    onChange={onDogKind}
                  ></input>
                  권모종
                </label>
              </div>
            </div>
            {/* 반려견 정보 div 끝 */}
            <div className="flex gap-4">
              <button
                onClick={() => navigate("/myPage")}
                className="w-full mx-auto mt-6 py-2 px-4 primary text-white rounded-btn text-center shadow-btn"
              >
                취소
              </button>
              <button
                onClick={putUserInfo}
                className="w-full mx-auto mt-6 py-2 px-4 primary text-white rounded-btn text-center shadow-btn"
              >
                변경
              </button>
            </div>
          </form>
        </div>
        <div className="flex my-2 mx-auto px-4">
          <button
            onClick={userLogOut}
            className="w-full text-slate-400 text-center text-xs"
          >
            로그아웃
          </button>
          <button
            onClick={showModal}
            className="w-full text-slate-400 text-center text-xs"
          >
            회원탈퇴
          </button>
          {modalOpen && <Unregister setModalOpen={setModalOpen} />}
        </div>
      </div>
    </div>
  );
}

export default ProfileEditPage;
