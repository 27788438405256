import { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Header from "../components/Header";
import WalkDoneModal from "../components/WalkDoneModal";
import Tmap from "../components/Tmap";

const WalkMap = () => {
  const [isModalShow, setIsModalShow] = useState(false);

  const [hospitals, setHospitals] = useState([]);
  const location = useLocation();
  const { startX, startY, destX, destY } = location.state;

  const handleHospitalButtonOnClick = async (evt) => {
    evt.preventDefault();
    if (hospitals.length) return;
    try {
      const response = await axios.post(
        "https://walkdog.azurewebsites.net/api/walk/nearby",
        {
          startCoordinate: { xCoordinate: startX, yCoordinate: startY },
          destinationCoordinate: {
            xCoordinate: destX,
            yCoordinate: destY,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setHospitals(response.data);
      }
    } catch (error) {}
  };

  return (
    <div className="my-3 p-6 bg-white rounded">
      <div className="flex justify-between ">
        <p className="font-bold text-lg text-center">🐶 즐거운 산책중!</p>
      </div>
      <div className="pt-4">
        <div className="w-full m-auto p-1 rounded-md border border-primary">
          <div className="h-[380px]">
            <Tmap
              from={{ lat: startY, lng: startX }}
              to={{ lat: destY, lng: destX }}
              hospitals={hospitals}
            />
          </div>
          <button
            className="w-400 mt-1 py-2 px-4 primary text-white rounded-lg text-center shadow-btn"
            onClick={handleHospitalButtonOnClick}
          >
            동물병원
          </button>
        </div>
        <button
          onClick={() => setIsModalShow(!isModalShow)}
          className="w-full mx-auto mt-4 py-2 px-4 primary text-white rounded-lg text-center shadow-btn"
        >
          산책완료!
        </button>
        {isModalShow && (
          <WalkDoneModal onClickClose={() => setIsModalShow(!isModalShow)} />
        )}
      </div>
    </div>
  );
};

function WalkingPage() {
  return (
    <div
      id="container"
      className="max-w-sm mx-auto min-w-[280px] divide-y-2 divide-slate-400/[.24]"
    >
      <Header />
      {/* 산책 진행 화면 */}
      <WalkMap />
    </div>
  );
}

export default WalkingPage;
