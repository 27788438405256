import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Unregister({ setModalOpen }) {
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false);
  };

  const navigate = useNavigate();
  async function unRegister() {
    try {
      const res = await axios.delete(
        "https://walkdog.azurewebsites.net/api/users",

        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
      console.log(res);
      navigate("/joinPage");
      window.localStorage.removeItem("token");
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className={"absolute flex justify-center items-center bg-opacity-0 "}>
      <div className="fixed bg-black bg-opacity-40 flex justify-center items-center top-0 right-0 bottom-0 left-0 z-10">
        <div className="w-[420px] max-w-[75%] bg-white px-6 pt-4 pb-8 rounded-modal text-center z-20">
          <div className="font-semibold my-4">정말로 탈퇴하시겠습니까?</div>
          <br />
          <div className="flex justify-between ">
            <button onClick={unRegister}>회원탈퇴</button>
            <button onClick={closeModal}>취소</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Unregister;
