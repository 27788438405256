import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";

function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onEmailHandler = (event) => {
    setEmail(event.currentTarget.value);
  };

  const onPWHandler = (event) => {
    setPassword(event.currentTarget.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  async function Login() {
    try {
      const res = await axios.post(
        "https://walkdog.azurewebsites.net/api/users/login",
        {
          email: email,
          password: password,
        }
      );
      localStorage.setItem("token", res.data.token);

      navigate("/");
    } catch (err) {
      console.error(err);
      alert("회원 정보가 일치하지 않습니다.");
    }
  }

  return (
    <div
      id="container"
      className="max-w-sm mx-auto min-w-[280px] divide-y-2 divide-slate-400/[.24]"
    >
      <Header state={1} />
      <div className="p-4 m-4 bg-white rounded">
        <p className="font-bold text-center text-title text-xl my-4">로그인</p>
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="my-2 p-3 rounded-md border border-primary">
            <div className="mb-2">
              <label>ID : </label>
              <input
                className="w-full p-1 border rounded-sm border-gray"
                name="email"
                value={email}
                onChange={onEmailHandler}
                placeholder="doggy@gmail.com"
              />
            </div>
            <div className="mb-2">
              <label>PW : </label>
              <input
                className="w-full p-1 border rounded-sm border-gray"
                type="password"
                name="password"
                value={password}
                onChange={onPWHandler}
                placeholder="비밀번호"
              />
            </div>
          </div>
          <button
            onClick={Login}
            className="w-full mx-auto mt-6 py-2 px-4 primary text-white rounded-btn text-center shadow-btn"
          >
            로그인
          </button>
        </form>
        <p className="mt-4 font-bold text-sm text-center text-teal-700">
          아직 계정이 없으신가요? <Link to="/joinPage">➡️ 회원가입하기</Link>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
