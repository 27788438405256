import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setLocalStorageObject } from "../utils/localStorage";
import Header from "../components/Header";
import RecordCalendar from "../components/RecordCalendar";
import Loading from "../components/Loading";

function MainPage() {
  const navigate = useNavigate();

  function toWalk() {
    navigate("/walkPage");
  }

  function toRoute() {
    navigate("/routePage");
  }

  const [data, setData] = useState("");
  const [degreeData, setDegreeData] = useState(null);

  function authCheck() {
    if (!localStorage.getItem("token")) {
      navigate("/loginPage");
    }
  }

  async function getUserInfo() {
    try {
      const res = await axios.get(
        "https://walkdog.azurewebsites.net/api/users",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setData(res.data);
      setLocalStorageObject("userInfo", res.data);
    } catch (err) {
      console.error(err);
      navigate("/loginPage");
    }
  }

  async function getDegree() {
    try {
      const res = await axios.post(
        "https://walkdog.azurewebsites.net/api/walk/degree",
        {
          xCoordinate: 127,
          yCoordinate: 36,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
      setDegreeData(res.data);
    } catch (err) {
      console.error(err);
      navigate("/loginPage");
    }
  }

  useEffect(() => {
    authCheck();
    getUserInfo();
    getDegree();
  }, []);

  const degreeText = {
    0: "매우나쁨",
    1: "나쁨",
    2: "보통",
    3: "좋음",
    4: "매우좋음",
  };

  const degreeIcon = {
    0: "😠",
    1: "☹️",
    2: "😐",
    3: "🙂",
    4: "😊",
  };

  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const dateStr = year + "년 " + month + "월 " + day + "일";

  return (
    <div
      id="container"
      className="max-w-sm mx-auto min-w-[280px] divide-y-2 divide-slate-400/[.24]"
    >
      <Header />
      <div className="px-4 my-3">
        <p className="font-bold text-lg py-4">{data.petName}, 산책가자</p>

        <div className="p-6 bg-white rounded">
          <div className="flex justify-between ">
            <p className="font-bold text-lg text-center">오늘의 산책지수</p>
            <p className="text-center">{dateStr}</p>
          </div>
          <div className="pt-4">
            {degreeData ? (
              <>
                <p className="text-center text-8xl mb-4">
                  {degreeIcon[degreeData.degree]}
                </p>
                <div className="w-full m-auto p-1 rounded-md border border-primary">
                  <p className="font-bold text-center text-primary text-xl my-2">
                    {degreeText[degreeData.degree]}
                  </p>
                  <div className="flex gap-4 justify-center my-2 text-sm">
                    <p>
                      기온:{" "}
                      <span className="font-bold">
                        {degreeData.temperatureDegree}
                      </span>
                    </p>
                    <p>
                      하늘상태:{" "}
                      <span className="font-bold">{degreeData.skyDegree}</span>
                    </p>
                    <p>
                      미세먼지:{" "}
                      <span className="font-bold">
                        {degreeData.particulateDegree}
                      </span>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <div className="flex my-4 mx-2 gap-4">
          <button
            onClick={toWalk}
            className="w-full mx-auto py-2 px-4 primary text-white rounded-btn text-center shadow-btn"
          >
            주변 목적지 추천
          </button>
          <button
            onClick={toRoute}
            className="w-full mx-auto py-2 px-4 primary text-white rounded-btn text-center shadow-btn"
          >
            주변 경로 추천
          </button>
        </div>
      </div>
      <div className="py-4">
        <RecordCalendar />
      </div>
    </div>
  );
}

export default MainPage;
