import React from "react";

function Modal({ isModalShow, onClickClose, onClickStart, data }) {
  const walkData = data.walkRecords;

  const scoreIcon = {
    1: "⭐️",
    2: "⭐️⭐️",
    3: "⭐️⭐️⭐️",
    4: "⭐️⭐️⭐️⭐️",
    5: "⭐️⭐️⭐️⭐️⭐️",
  };

  return (
    <>
      <div
        className={
          "absolute flex justify-center items-center bg-opacity-0 " +
          (isModalShow ? "visible" : "invisible")
        }
      >
        <div className="fixed bg-black bg-opacity-40 flex justify-center items-center top-0 right-0 bottom-0 left-0 z-10">
          <div className="w-[320px] bg-white px-6 py-4 rounded-modal text-center z-20">
            <div className="flex justify-between">
              <h1 className="h-full mb-3 mt-4 text-lg font-bold text-black">
                {data.name}
              </h1>
              <button className="rounded-lg text-center" onClick={onClickClose}>
                X
              </button>
            </div>
            {walkData.length !== 0 ? (
              <div className="h-[130px] overflow-y-scroll">
                {walkData.map((data, index) => (
                  <div
                    key={index}
                    className="text-left p-2 border-2 border-slate-500/5"
                  >
                    <p>{data.score ? `${scoreIcon[data.score]}` : "_"} </p>
                    <p>{data.memo ? data.memo : "_"}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="font-bold text-sm text-teal-700">
                아직 후기가 없어요!
                <br />첫 산책 후기를 남겨주세요!
              </p>
            )}
            <button
              className="w-full mt-4 py-3 px-4 secondary rounded-btn text-center shadow-btn"
              onClick={onClickStart}
            >
              출발!
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
